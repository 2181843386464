<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />

    <LandingForm
      :landing="currentLanding"
    />
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import landings from './landingData'
import LandingForm from './LandingForm.vue'

export default {
  components: {
    LandingForm,
    Breadcrumbs,
    UserToolbar,
  },
  data() {
    return {
      breadcrumbItems: [],
      account: {},
    }
  },
  computed: {
    currentLanding() {
      if (landings.length > 0 && this.$route.params.landingId !== undefined) {
        return landings.find(l => l._id === this.$route.params.landingId)
      }

      return []
    },
  },
  created() {
    this.getUserData()
  },
  methods: {
    setBreabcrumbs() {
      if (this.$route.params.userId !== undefined) {
        this.breadcrumbItems = [
          {
            text: 'users',
            to: '/users',
          },
          {
            text: this.account.name,
            to: `/users/${this.$route.params.userId}`,
          },
          {
            text: 'landings',
            active: true,
          },
        ]
      } else {
        this.breadcrumbItems = [
          {
            text: 'Landings',
            active: true,
          },
        ]
      }
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || []
      this.setBreabcrumbs()
    },
  },
}

</script>
