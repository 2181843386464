<template>
  <div>
    <b-tabs pills>
      <b-tab
        v-if="authUserData.role.includes('admin')"
        active
      >
        <template #title>
          <feather-icon
            icon="EditIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{ $t('Landing AI Generator') }}</span>
        </template>

        <b-card>
          <div class="head-card">
            <div class="head-card-top">
              <h4>{{ $t('Describe your landing page') }}</h4>
              <feather-icon
                v-b-tooltip.hover.top="$t('Close')"
                icon="XIcon"
                size="18"
                class="toggle-close cursor-pointer"
                @click="$router.go(-1)"
              />
            </div>
            <hr>
            <b-form-textarea
              v-model="userDescription"
              placeholder="Describe what you want..."
              rows="3"
            />
            <b-button
              class="mt-2"
              variant="success"
              @click="generateLanding"
            >
              <feather-icon
                icon="CpuIcon"
                class="mr-25"
              />
              <span>Generate Landing Page</span>
            </b-button>
          </div>
        </b-card>
        <b-card class="mt-2">
          <h4>{{ $t('Generated Landing') }}</h4>
          <div
            v-for="form in generatedData.forms"
            :key="form.id"
          >
            <div
              v-for="(field, idx) in form.fields"
              :key="`${field.type}-${idx}`"
            >
              <p>
                <strong>{{ field.type }}:</strong> {{ field.text }}
              </p>
            </div>
          </div>
        </b-card>
      </b-tab>

      <b-tab v-if="authUserData.role.includes('admin')">
        <template #title>
          <feather-icon
            icon="ServerIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{ $t('Form') }}</span>
        </template>
        <b-card>
          <div class="head-card">
            <div class="head-card-top">
              <h4>{{ $t('Landing form') }}</h4>
              <feather-icon
                v-b-tooltip.hover.top="$t('Close')"
                icon="XIcon"
                size="18"
                class="toggle-close cursor-pointer"
                @click="$router.go(-1)"
              />
            </div>
            <hr>
          </div>

          <div v-if="landingData.forms">
            <div
              v-for="form in landingData.forms"
              :key="form.id"
            >
              <h4 class="mb-1">
                {{ form.title }}
              </h4>
              <div
                v-for="(formField, idx) in form.fields"
                :key="`${formField.type}-${idx}`"
              >
                <b-row class="mb-1">
                  <b-col cols="1">
                    {{ idx + 1 }}
                  </b-col>
                  <b-col cols="3">
                    <b-form-select
                      v-model="formField.type"
                      :options="formTypes"
                    />
                  </b-col>
                  <b-col cols="3">
                    <b-form-input
                      :id="`${formField.type}-${idx}`"
                      v-model="formField.text"
                      placeholder="Placeholder text"
                    />
                  </b-col>
                  <b-col cols="3">
                    <b-button
                      variant="danger"
                      size="sm"
                      @click="deleteField(form.fields, idx)"
                    >
                      <feather-icon
                        v-b-tooltip.hover.top="$t('Delete')"
                        icon="XIcon"
                        size="18"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </div>

              <b-button
                variant="primary"
                @click="addField(form.fields)"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add Field</span>
              </b-button>

              <b-form-group
                label="Enable additional question?"
                class="mt-2"
              >
                <b-form-checkbox
                  v-model="showAdditionalMessage"
                >
                  Enable additional question
                </b-form-checkbox>
              </b-form-group>

              <b-form-input
                v-if="showAdditionalMessage"
                v-model="additionalMessage"
                placeholder="Enter the message after form submission"
                class="mt-2 mb-2"
              />

              <b-form-group
                label="Success form send message "
              >
                <b-form-input
                  v-model="successSendMessage"
                  placeholder="Your form has been successfully"
                />
              </b-form-group>

              <b-button
                variant="success"
                class="mt-2"
                :disabled="disabledBtn"
                @click="saveForm"
              >
                <span>Submit</span>
              </b-button>
            </div>
          </div>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import {
  BCard,
  BTabs,
  BTab,
  VBTooltip,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BFormSelect,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BFormSelect,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    landing: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {
      authUserData: getUserData(),
      breadcrumbItems: [],
      account: {},
      landingData: {},
      generatedData: {},
      userDescription: '',
      generatedLanding: '',
      formTypes: [
        { value: 'text', text: 'Text' },
        { value: 'tel', text: 'Telephone' },
        { value: 'email', text: 'E-mail' },
        { value: 'select', text: 'Select' },
        { value: 'textarea', text: 'Textarea' },
        { value: 'button', text: 'Button' },
      ],
      showAdditionalMessage: false,
      additionalMessage: '',
      showConfirmation: false,
      disabledBtn: false,
      successSendMessage: 'Your form has been successfully submitted. Thank you!',
    }
  },
  created() {
    this.landingData = this.landing
    this.getUserData()
  },
  methods: {
    setBreabcrumbs() {
      if (this.$route.params.userId !== undefined) {
        this.breadcrumbItems = [
          {
            text: 'users',
            to: '/users',
          },
          {
            text: this.account.name,
            to: `/users/${this.$route.params.userId}`,
          },
          {
            text: 'landings',
            active: true,
          },
        ]
      } else {
        this.breadcrumbItems = [
          {
            text: 'Landings',
            active: true,
          },
        ]
      }
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || []
      this.setBreabcrumbs()
    },
    addField(fields) {
      fields.push({
        type: 'text',
        text: 'New Field',
      })
      this.generatedData = {}
    },
    deleteField(fields, idx) {
      if (idx >= 0 && idx < fields.length) {
        fields.splice(idx, 1)
      }
      this.generatedData = {}
    },
    generateLanding() {
      this.generatedData = {}
      this.generatedData = this.landingData
    },
    saveForm() {
      this.disabledBtn = true
      setTimeout(() => {
        this.disabledBtn = false
      }, 1500)
    },
  },
}
</script>

<style scoped>
.landing-preview {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f9f9f9;
  min-height: 150px;
}
</style>
