const landings = [
  {
    _id: 'ew2e3ws7gg863he',
    user_id: '607d3be2c99536d189d3900c',
    title: 'Test landing 1',
    status: 'active',
    forms: [
      {
        form_id: 1,
        title: 'Form 1',
        fields: [
          {
            type: 'text',
            text: 'Name',
          },
          {
            type: 'tel',
            text: 'Phone number',
          },
          {
            type: 'email',
            text: 'E-mail',
          },
          {
            type: 'textarea',
            text: 'Message',
          },
          {
            type: 'button',
            text: 'Send',
          },
        ],
      },
    ],
  },
]

export default landings
